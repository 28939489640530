import React from 'react';
import './BehindAnalysis.css';

function BehindAnalysis() {
  return (
    <div className="behind-analysis-container">
      <h2 className="behind-analysis-title">Behind the Analysis</h2>
      <p className="behind-analysis-text">
        Our analysis process combines raw data from SEC filings, a proven analytical framework,
        and the power of AI to provide clear, actionable insights.
      </p>
      <ul className="behind-analysis-list">
        <li><strong>Earnings in Focus:</strong> Decoding Earnings Per Share trends with consistency and precision.</li>
        <li><strong>Data Integrity:</strong> Leveraging data from 10-K and 10-Q forms to ensure accuracy.</li>
        <li><strong>Key Metrics:</strong> Revenue, net income, and efficiency ratios are analyzed to uncover the story behind the numbers.</li>
        <li><strong>Predictions You Can Trust:</strong> EPS forecasts are paired with confidence levels for transparency.</li>
      </ul>
    </div>
  );
}

export default BehindAnalysis;